/* jshint esversion: 6 */
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './slidingLabel';

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    /* Basket logic */
    let quantityField = $('.iw-quantityAction .iw-quantityField');

    quantityField.on('click', function () {
        $(this).focus();
    });

    quantityField.on('blur', function () {
        $(this).closest('.iw-quantityAction').submit();
    });

    $('.iw-quantityAction, .iw-removeAction').on('submit', function () {
        $('.loadingIndicator').removeClass('d-none');
    });

    /* Address-Form */

    $('#addressForm .iw-addressSelection input[type="radio"]').on('change', function () {
        if (!$(this).is(':checked')) {
            return;
        }

        if (parseInt($(this).val()) === 1) {
            $('#addressForm .iw-deliveryAddress').removeClass('d-none');
        } else {
            $('#addressForm .iw-deliveryAddress').addClass('d-none');
        }
    }).change();

    /* Payment logic */

    $('.iw-chosePaymentContainer .iw-primaryButtonContainer').on('click', function () {
        if (!$('input[name ="paymentAdapter"]').is(':checked')) {
            return;
        }
        $('.iw-chosePaymentContainer').hide();
        $('.iw-submitPaymentContainer').show();
    });

    $('.iw-submitPaymentContainer .iw-paymentBackButton').on('click', function () {
        $('.iw-submitPaymentContainer').hide();
        $('.iw-chosePaymentContainer').show();
    });

    $('.iw-chosePaymentContainer input[name="paymentAdapter"]').on('change', function () {
        if ($(this).is(':checked')) {
            let paymentInformationContainer = $('.iw-paymentInformationContainer input[name ="paymentAdapter"]:checked').closest('.iw-paymentInformationContainer');
            $('.iw-submitPaymentContainer .iw-selectedPaymentTitle').text(paymentInformationContainer.find('.iw-selectedPaymentTitle').text());
            $('.iw-submitPaymentContainer .iw-selectedPaymentDescription').text(paymentInformationContainer.find('.iw-selectedPaymentDescription').text());
            $('.iw-submitPaymentContainer .iw-selectedPaymentImage').html(paymentInformationContainer.find('.iw-selectedPaymentImage').html());

            $('.iw-chosePaymentContainer .iw-primaryButtonContainer').removeClass('iw-disabled');
        } else {
            $('.iw-chosePaymentContainer .iw-primaryButtonContainer').addClass('iw-disabled');
        }
    }).change();

    $('#termsAndConditions').on('change', function () {
        if ($(this).is(':checked')) {
            $('.iw-submitPaymentContainer .iw-submitButtonContainer').removeClass('iw-disabled');
            $('.iw-submitPaymentContainer .iw-submitButtonContainer input').attr('disabled', false);
        } else {
            $('.iw-submitPaymentContainer .iw-submitButtonContainer').addClass('iw-disabled');
            $('.iw-submitPaymentContainer .iw-submitButtonContainer input').attr('disabled', true);
        }
    }).change();
});
