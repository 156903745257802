/* jshint esversion: 6 */
import $ from 'jquery';

$(function () {
    $('.slidingLabel input').on('change', function () {
        if ($(this).val() === '') {
            $(this).removeClass('filled');
        } else {
            $(this).addClass('filled');
        }
    }).change();
});
